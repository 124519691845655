import { Row, Col, Checkbox, Tag, Typography } from "antd";
import {
  BasicDataTable,
  MoreButton,
  useIntl,
  NotificationContext,
  MoreButtonItemProps,
} from "@datwyler/mfe-shared-components";
import {
  copyToClipboard,
  getLocationFromSiteId,
  getTelemetryData,
} from "../../../utils";
import { OFF, ON } from "../../../utils/constants";
import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

type MoreButtonProps = typeof MoreButtonItemProps;
const { Text } = Typography;

const DeviceTable = (props) => {
  const {
    data,
    locations,
    pageNumber,
    rowsPerPage,
    setRowsPerPage,
    setPageNumber,
    selected,
    setSelected,
  } = props;
  const intl = useIntl();
  const { openNotification }: any = useContext(NotificationContext);
  const navigate = useNavigate();
  const location = useLocation();

  const onChangeRowsPerPage = (val) => {
    setRowsPerPage(parseInt(val));
    setPageNumber(1);
  };

  const onChangePage = (val) => {
    setPageNumber(val);
  };

  const getTableData = () => {
    return (
      data?.devices?.map((device) => {
        const withKey = JSON.parse(JSON.stringify(device));
        withKey.key = withKey.id;
        return withKey;
      }) || []
    );
  };

  const onClickCheckbox = (record) => {
    const newSelected = JSON.parse(JSON.stringify(selected));
    const index = indexInSelected(record);

    if (index >= 0) {
      // if already selected
      newSelected.splice(index, 1);
    } else {
      newSelected.push(record);
    }

    setSelected(newSelected);
  };

  const indexInSelected = (record) => {
    return selected.findIndex((device) => device.id === record.id);
  };

  const onSelectAll = () => {
    if (selected.length === data?.devices?.length) {
      setSelected([]);
    } else {
      setSelected(data.devices);
    }
  };

  const columns = [
    {
      title: (
        <Checkbox
          onChange={onSelectAll}
          checked={selected.length === data?.devices?.length}
        />
      ),
      key: "isChecked",
      render: (_, record) => (
        <Checkbox
          onClick={(event) => {
            event.stopPropagation();
          }}
          onChange={() => {
            onClickCheckbox(record);
          }}
          checked={indexInSelected(record) >= 0}
        />
      ),
      width: "40px",
    },
    {
      title: intl.formatMessage({ id: "device_name" }),
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "type" }),
      dataIndex: "type",
      render: (_, { type }) => {
        return <Text>{intl.formatMessage({ id: type })}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "device_id" }),
      dataIndex: "id",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "location" }),
      ellipsis: true,
      render: (_, { site }) => {
        const location = getLocationFromSiteId(locations, site.id);
        return <Text>{location?.name}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "site" }),
      dataIndex: ["site", "name"],
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "telemetry",
      render: (_, { telemetry }) => {
        const status = getTelemetryData(telemetry, "status") || OFF;
        const color = status === ON ? "green" : "red";
        const label =
          status === ON
            ? intl.formatMessage({ id: "online" })
            : intl.formatMessage({ id: "offline" });

        return (
          <Tag color={color} key={status}>
            {status ? intl.formatMessage({ id: label }) : ""}
          </Tag>
        );
      },
    },
    {
      dataIndex: "action",
      render: (_, record) => {
        return <MoreButton items={getMoreButtonItems(record)} />;
      },
      width: "56px",
    },
  ];

  const getMoreButtonItems = (record) => {
    const device = record;
    const options: MoreButtonProps[] = [
      {
        label: intl.formatMessage({ id: "copy_device_id" }),
        onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          event.stopPropagation();
          copyToClipboard(device, openNotification, intl);
        },
      },
    ];
    return options;
  };

  const handleOnClick = (device) => {
    navigate("/device-monitoring/deviceDetails/", {
      state: {
        ...location.state,
        device: device,
      },
    });
  };

  return (
    <Row style={{ marginTop: "24px" }}>
      <Col span={24}>
        {data?.devices?.length > 0 && (
          <BasicDataTable
            data={getTableData()}
            columns={columns}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            total={data?.page?.totalElements}
            style={{ cursor: "pointer" }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleOnClick(record);
                }, // click row
              };
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default DeviceTable;
